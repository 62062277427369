@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  margin: 0;
  background-color: var(--light-background);
  overflow-x: hidden;
}

:root {
  --almost-black: #242424;
  --primary-light-blue: #337ab7;
  --light-blue-background: #5cc6f6;
  --light-background: #f2f3f5;
  --primary-dark-blue: #00365e;
  --primary-light-gray: #edeef2;
  --secondary-light-gray: #b6b7bc;
  --primary-dark-gray: #4a494a;
  --primary-white-gray: #f4f7f8;
  --default-pdf-width: 495px;
  --default-pdf-height: 700px;
  --footer-light-gray: #838a8e;
  --light-border: #c1c1c1;
  --hoover-blue: #005887;
  --icon-color: #1c1b1f;
  --primary-blue-light: #0072af;
  --success: #7dc443;
  --warning: #c40000;

  --s-base: 8px;
  --s-2x: 16px;
  --s-4x: 18px;

  --ecg-navy-blue: #020c4a;
  --ecg-teal-blue: #1bc0d3;
  --ecg-royal-blue: #2451cb;
  --ecg-soft-powder-blue: #eff7fe;
}

* {
  font-family: 'Open Sans', sans-serif;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.Modal {
  position: absolute;
  top: auto;
  background-color: #fff;
  z-index: 10;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.DefaultPdfLoading {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
